import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Breadcrumb from '../../components/breadcrumb'
import GenericPage from '../../components/genericPage'

import content from '../../json/2021/demarche.json'

const DemarchePage = ({location}) => {
  return (
    <Layout year="2021" simpleHeader={true} url="/2021/notre-demarche">
      <SEO 
        title="La démarche : les marques sont-elles vertueuses sur le web ? - 2021"
        description="Notre démarche ? Mesurer l’impact utilisateur, environnemental et sociétal des sites des plus grandes marques françaises on-line."
        path="/2021/notre-demarche"
        year="2021" />

      <Breadcrumb url="/2021/notre-demarche" label="Notre démarche" type="demarche" year="2021" /> 

      <GenericPage slug="demarche" content={content} />
    </Layout>
  )
  }

export default DemarchePage

